import { Form } from "react-bootstrap";

const GlobalInput = ({ label, placeholder, type, value }) => {
  const checkFieldValue = (value) => {
    let result;
    if (typeof value !== "number") {
      result =
        "Current Status: " + value?.charAt(0).toUpperCase() + value?.slice(1);
    } else {
      result = "Money in System: " + value;
    }
    return result;
  };
  return (
    <Form.Group controlId="validationCustom01">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        // className={
        //   value && typeof value === "number" && value <= 0
        //     ? "text-danger"
        //     : value && typeof value === "number" && value > 0
        //     ? "text-success"
        //     : ""
        // }
        required
        type={type}
        placeholder={placeholder}
        value={value && checkFieldValue(value)}
        readOnly
      />
    </Form.Group>
  );
};
export default GlobalInput;
