import ExportExcel from "../global/exportExcel";
import SelectInput from "../global/selectInput";
import TableColumns from "../global/tableColumns";
import GlobalTable from "../global/globalTable";
import { useEffect, useState } from "react";
import Pagination from "../../utils/Pagination";
import { useMyContect } from "../../context/myContext";
import { getAllAgentTransactions } from "../../utils/Apis";
import { formatDate } from "../../utils/dateTimeFromater";

const AgentTransactionTable = ({ recall, filter }) => {
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const [pageSize, setPageSize] = useState("20");

  let options = [
    {
      id: 1,
      value: 20,
    },
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
    {
      id: 1,
      value: 500,
    },
  ];
  const [rowData, setRowData] = useState([]);
  console.log(rowData);
  const getAllPlayerData = async (page, pageSize) => {
    try {
      const config = {
        params: {
          page: page,
          pageSize: pageSize,
        },
        filter: filter,
      };
      setLoading(true);
      const res = await getAllAgentTransactions(config);
      console.log(res);
      if (res.status === 200) {
        setRowData(res.data);
        setLoading(false);
      }
      console.log("Fetching data function placeholder");
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPlayerData(page, pageSize);
    // eslint-disable-next-line
  }, [pageSize, page, recall, isLoading]);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      minWidth: 120,
      sortable: true,
      flex: 1,
      cellStyle: { color: "#4caf50", textAlign: "center" },
    },
    {
      headerName: "Date Created",
      field: "createdAt",
      minWidth: 150,
      flex: 1,
      // sortable: true,
      sort: "desc",
      cellStyle: { textAlign: "center" },
      valueFormatter: (params) => formatDate(params?.value),
    },
    {
      headerName: "Amount",
      field: "amount",
      minWidth: 200,
      flex: 1,
      // cellStyle: {  color: "#4caf50" },
      cellRenderer: (params) =>
        params.data.type === "deposit"
          ? "+ " + params.value
          : "- " + params.value,
      cellStyle: (params) => {
        return {
          color: params.data.type === "deposit" ? "#4caf50" : "#f44336",
        };
      },
    },
    {
      headerName: "Level",
      field: "executor",
      minWidth: 110,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => params.value.level,
    },
    {
      headerName: "Manager",
      field: "executor",
      minWidth: 250,
      flex: 1,
      sortable: true,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => params.value.username,
    },
    {
      headerName: "After Balance",
      field: "rawBalance",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) =>
        params.data.type === "deposit"
          ? params.value + params.data.amount
          : params.value - params.data.amount,
    },
  ]);

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );

  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  console.log(setRowData, setPages);

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Agents Transaction History</h6>
        <div className="right-area">
          <ExportExcel rowData={rowData} tableData={tableData} />
          <SelectInput
            listArray={options}
            defaultOption="20"
            handleSelectOption={(e) => {
              setPageSize(e.target.value ? e.target.value : "20");
            }}
          />
          <TableColumns tableData={tableData} checkedBox={checkedBox} />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : (
        // : rowData?.length === 0 ? (
        //   <h4 className="text-center my-5">No Data Found</h4>
        // )
        <>
          <GlobalTable columnDefs={columnDefs} rowData={rowData} loading={loading}/>
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

export default AgentTransactionTable;
