import { io } from "socket.io-client";
import { serverPath } from "./keys";

// landing-server socket
const socket = io(serverPath, {
  query: { token: localStorage.getItem("accToken") },
  transports: ["websocket"], // Ensure server supports WebSocket
  rejectUnauthorized: false,
  reconnection: true, // Allow reconnections automatically
  reconnectionAttempts: 5, // Retry connection 5 times
  reconnectionDelay: 1000, // Delay between reconnections
});

console.log("Token Find ====>>>", localStorage.getItem("accToken"));
console.log("Server path check ======>>>", serverPath);

// Correct client-side connect event
socket.on("connect", () => {
  console.log("Connected successfully");
});

socket.on("disconnect", () => {
  console.log("Disconnected");
});

// Try manual reconnection logic
const tryReconnect = () => {
  setTimeout(() => {
    console.log("Trying to reconnect...");
    socket.io.open((err) => {
      if (err) {
        console.error("Reconnection failed, trying again...");
        tryReconnect();
      } else {
        console.log("Reconnected successfully with tryReconnect");
      }
    });
  }, 1000);
};

// Handling disconnection
socket.io.on("close", tryReconnect);

export default socket;
