import { adminInstance, authInstance } from "../config/axios";
// import toast from "react-hot-toast";
// ============================authInstance=================================
export const loginUser = async (body) => {
  try {
    const res = await authInstance().post("/v1/admin/agent/login", body);
    return res?.data;
  } catch (error) {
    // toast.error(error.message)
  }
};

export const logoutUser = async (body) => {
  // console.log("data===>", body);
  try {
    const res = await authInstance().post("/v1/auth/logout", body);
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getLoginDetails = async () => {
  // console.log("data===>", body);
  try {
    const res = await adminInstance().get("/v1/admin");
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createSiteContentData = async (body) => {
  try {
    const res = await adminInstance().post("/v1/admin/site-create", body);
    return res;
  } catch (error) {
    console.log("error", error);
    return error.response.data;
  }
};

export const updateSiteContentData = async (body) => {
  try {
    const res = await adminInstance().post("/v1/admin/edit-site", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllSiteContent = async () => {
  try {
    const res = await adminInstance().get("/v1/admin/getAllSiteContent");
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllDomainName = async () => {
  try {
    const res = await adminInstance().get("/v1/admin/getAllDomain");
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllAgents = async (config) => {
  const { params, filter } = config;

  try {
    const res = await adminInstance().get(
      `/v1/admin/agent?page=${params.page}&pageSize=${params.pageSize}` +
        `${filter?.id ? `&id=${filter.id}` : ""}` +
        `${filter?.username ? `&username=${filter.username}` : ""}` +
        `${filter?.level ? `&level=${filter.level}` : ""}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllTransactions = async (config) => {
  const { params, filter } = config;

  try {
    const res = await adminInstance().get(
      `/v1/admin/agent/transactions?page=${params.page}&pageSize=${params.pageSize}` +
        `${filter?.id ? `&id=${filter.id}` : ""}` +
        `${filter?.username ? `&username=${filter.username}` : ""}` +
        `${filter?.level ? `&level=${filter.level}` : ""}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const getAllAgentTransactions = async (config) => {
  const { params, filter } = config;
  try {
    const res = await adminInstance().get(
      `/v1/admin/agent/agent-transaction?page=${params.page}&pageSize=${params.pageSize}` +
        `${filter?.id ? `&id=${filter.id}` : ""}` +
        `${filter?.username ? `&username=${filter.username}` : ""}` +
        `${filter?.level ? `&level=${filter.level}` : ""}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllUserTransactions = async (config) => {
  const { params, filter } = config;
  try {
    const res = await adminInstance().get(
      `/v1/admin/agent/user-transaction?page=${params.page}&pageSize=${params.pageSize}` +
        `${filter?.id ? `&id=${filter.id}` : ""}` +
        `${filter?.username ? `&username=${filter.username}` : ""}` +
        `${filter?.level ? `&level=${filter.level}` : ""}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createAgent = async (body) => {
  try {
    const res = await adminInstance().post("/v1/admin/agent", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const agentPayment = async (body) => {
  try {
    const res = await adminInstance().post("/v1/admin/agent/payment", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const userPayment = async (body) => {
  try {
    const res = await adminInstance().post(
      "/v1/admin/agent/user-payment",
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const generateDomainToken = async (value) => {
  try {
    const res = await adminInstance().post("/v1/admin/domainToken", {
      tokenValue: value,
    });
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};

// export const getPlayerData = async (config) => {
//   const { params, filter } = config;
//   try {
//     const res = await adminInstance().get(
//       `/v1/admin/getPlayerData?page=${params.page}&pageSize=${params.pageSize}` +
//         `${filter?.id ? `&id=${filter.id}` : ""}` +
//         `${filter?.userName ? `&userName=${filter.userName}` : ""}` +
//         `${filter?.firstName ? `&firstName=${filter.firstName}` : ""}` +
//         `${filter?.lastName ? `&lastName=${filter.lastName}` : ""}` +
//         `${filter?.email ? `&email=${filter.email}` : ""}` +
//         `${filter?.mobile ? `&mobile=${filter.mobile}` : ""}` +
//         `${filter?.country ? `&country=${filter.country}` : ""}` +
//         `${filter?.city ? `&city=${filter.city}` : ""}` +
//         `${filter?.address ? `&address=${filter.address}` : ""}` +
//         `${filter?.fromBalance ? `&fromBalance=${filter.fromBalance}` : ""}` +
//         `${filter?.toBalance ? `&toBalance=${filter.toBalance}` : ""}` +
//         `${
//           filter?.iskycCompleted
//             ? `&iskycCompleted=${filter.iskycCompleted === "Verified"}`
//             : ""
//         }`
//     );

//     return res;
//   } catch (error) {
//     return error.response.data;
//   }
// };

export const getPlayerData = async (config) => {
  const { params, filter } = config;
  try {
    const res = await adminInstance().get(
      `/v1/admin/agent/users?page=${params.page}&pageSize=${params.pageSize}` +
        `${filter?.id ? `&id=${filter.id}` : ""}` +
        `${filter?.userName ? `&userName=${filter.userName}` : ""}` +
        `${filter?.firstName ? `&firstName=${filter.firstName}` : ""}` +
        `${filter?.lastName ? `&lastName=${filter.lastName}` : ""}` +
        `${filter?.email ? `&email=${filter.email}` : ""}` +
        `${filter?.mobile ? `&mobile=${filter.mobile}` : ""}` +
        `${filter?.country ? `&country=${filter.country}` : ""}` +
        `${filter?.city ? `&city=${filter.city}` : ""}` +
        `${filter?.address ? `&address=${filter.address}` : ""}` +
        `${filter?.fromBalance ? `&fromBalance=${filter.fromBalance}` : ""}` +
        `${filter?.toBalance ? `&toBalance=${filter.toBalance}` : ""}` +
        `${
          filter?.iskycCompleted
            ? `&iskycCompleted=${filter.iskycCompleted === "Verified"}`
            : ""
        }`
    );

    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const verifyEmail = async (body) => {
  try {
    const res = await adminInstance().post(
      "v1/auth/send-verification-email",
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const emailVerified = async (body) => {
  try {
    const res = await adminInstance().post("v1/auth/verify-email", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getTransactionData = async (body) => {
  try {
    const res = await adminInstance().get(
      "/v1/admin/getTransactionDetails",
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getGameTransactionData = async (config) => {
  const { filter, params, body } = config;
  try {
    const res = await adminInstance().get(
      `/v1/admin/getGameTransaction?page=${params?.page}&pageSize=${
        params?.pageSize
      }${
        filter
          ? `&gameId=${filter?.gameId || ""}&fromAmount=${
              filter?.fromAmount || ""
            }&toAmount=${filter?.toAmount || ""}&userId=${
              filter?.userId || ""
            }&fromDate=${filter?.fromDate || ""}&toDate=${
              filter?.toDate || ""
            }&transactionId=${filter?.transactionId || ""}&actionType=${
              filter?.actionType || ""
            }`
          : ""
      }`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const createTestimonials = async (body) => {
  try {
    const res = await adminInstance().post(
      "/v1/admin/createTestimonials",
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getTestimonials = async (body) => {
  try {
    const res = await adminInstance().get("/v1/admin/getTestimonial", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updateTestimonials = async (body) => {
  try {
    const res = await adminInstance().put(
      `/v1/admin/updateTestimonials/${body?.body.id}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteTestimonials = async (body) => {
  try {
    console.log("delete body", body);
    const res = await adminInstance().delete(
      `/v1/admin/deleteTestimonial/${body?.body.id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllNews = async (body) => {
  try {
    const res = await adminInstance().get("/v1/admin/getAllNews", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createSiteText = async (body) => {
  try {
    const res = await adminInstance().post("/v1/admin/createSiteText", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllSiteText = async (body) => {
  try {
    const res = await adminInstance().get("/v1/admin/getSiteText", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updateSiteText = async (id, body) => {
  try {
    const res = await adminInstance().put(
      `/v1/admin/updateSiteText/${id}`,
      body?.body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteSiteText = async (body) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deleteSiteText/${body?.body.id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createNews = async (config, formData) => {
  try {
    const res = await adminInstance().post(
      "/v1/admin/createNews",
      formData,
      config
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const updateNews = async (id, config, formData) => {
  try {
    console.log(id, config, formData);
    const res = await adminInstance().put(
      `/v1/admin/updateNews/${id}`,
      formData,
      config
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteNews = async (id) => {
  try {
    const res = await adminInstance().delete(`/v1/admin/deleteNews/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const createPromotion = async (config, formData) => {
  try {
    const res = await adminInstance().post(
      "/v1/admin/createPromotions",
      formData,
      config
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const getAllPromotions = async (body) => {
  try {
    const res = await adminInstance().get("/v1/admin/getAllPromotions", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllPaymentMethods = async (body) => {
  try {
    const res = await adminInstance().get("/v1/admin/paymentmethods");
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllPaymentProviders = async (body) => {
  try {
    const res = await adminInstance().get("/v1/admin/paymentproviders");
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllPaymentChannels = async (body) => {
  try {
    const res = await adminInstance().get("/v1/admin/paymentchannels");
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllPaymentGroups = async (body) => {
  try {
    const res = await adminInstance().get("/v1/admin/paymentgroups");
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createPaymentProvider = async (body) => {
  // console.log("providers", body.body?.id);
  try {
    const res = await adminInstance().post(
      `/v1/admin/addUpdateProvider${
        body.body?.id ? `?providerId=${body.body?.id}` : ""
      }`,
      body?.body
    );
    return res;
  } catch (error) {
    console.log("error", error);
    return error.response.data;
  }
};

export const createPaymentChannel = async (body) => {
  // console.log("providers", body);
  try {
    const res = await adminInstance().post(
      `/v1/admin/addUpdateChannel${
        body.body?.id ? `?channelId=${body.body?.id}` : ""
      }`,
      body?.body
    );
    return res;
  } catch (error) {
    // console.log("error", error);
  }
};

export const updatePromotion = async (id, config, formData) => {
  try {
    const res = await adminInstance().put(
      `/v1/admin/updatePromotions/${id}`,
      formData,
      config
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const deletePromotion = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deletePromotions/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const getAllPopups = async (config) => {
  try {
    const { params } = config;
    const res = await adminInstance().get(
      `/v1/admin/getAllPopups?${
        params ? `page=${params?.page}&pageSize=${params?.pageSize}` : ""
      }`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteProvider = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deletepaymentprovider/${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createPopup = async (config, formData) => {
  try {
    const res = await adminInstance().post(
      "/v1/admin/createPopups",
      formData,
      config
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updatePopup = async (id, config, formData) => {
  try {
    const res = await adminInstance().put(
      `/v1/admin/updatePopups/${id}`,
      formData,
      config
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deletePopup = async (id) => {
  try {
    const res = await adminInstance().delete(`/v1/admin/deletePopups/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const createPaymentGroup = async (formData, id) => {
  console.log("providers", formData);
  try {
    const res = await adminInstance().post(
      `/v1/admin/addUpdateGroup${id ? `?groupId=${id}` : ""}`,
      formData
    );
    return res;
  } catch (error) {
    console.log("error", error);
  }
};
export const createSocial = async (config, formData) => {
  try {
    const res = await adminInstance().post(
      "/v1/admin/createSocial",
      formData,
      config
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addUpdateScriptContent = async ({ body }) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/addUpdateScriptContent`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getScriptContent = async () => {
  try {
    const res = await adminInstance().get(`/v1/admin/getScriptContent`);
    console.log(res);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addUpdateGeneralContent = async (body) => {
  console.log("body===", body);
  try {
    const res = await adminInstance().post(
      `/v1/admin/addUpdateGeneralContent`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getGeneralContent = async () => {
  try {
    const res = await adminInstance().get(`/v1/admin/getGeneralContent`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const saveStaticPages = async () => {
  try {
    const res = await adminInstance().get(`/v1/admin/saveStaticPages`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deletePaymentGroup = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deletepaymentgroup/${id}`
    );

    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const updateSocial = async (id, config, formData) => {
  try {
    const res = await adminInstance().put(
      `/v1/admin/updateSocial/${id}`,
      formData,
      config
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllSocial = async (body) => {
  try {
    const res = await adminInstance().get("/v1/admin/getAllSocial", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteSocial = async (id) => {
  try {
    const res = await adminInstance().delete(`/v1/admin/deleteSocial/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const getBannerPageData = async (body) => {
  try {
    const res = adminInstance().get("/v1/admin/getAllBannerPage", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createBanner = async (config, formData) => {
  try {
    const res = adminInstance().post(
      "/v1/admin/createBanner",
      formData,
      config
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updateBanner = async (id, config, formData) => {
  try {
    const res = await adminInstance().put(
      `/v1/admin/updateBanner/${id}`,
      formData,
      config
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getBannerData = async (body) => {
  try {
    const res = adminInstance().get("/v1/admin/getAllBanner", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteBanner = async (id) => {
  try {
    const res = await adminInstance().delete(`/v1/admin/deleteBanner/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const templatesandusers = async () => {
  try {
    const res = await adminInstance().get(`/v1/admin/templatesAndusers`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllNotifications = async ({ body, page, timePeriod }) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/notifications?page=${page}&timePeriod=${timePeriod}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllNotificationTemplate = async ({
  body,
  page,
  timePeriod,
}) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/notificationTemplates?page=${page}&timePeriod=${timePeriod}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addUpdateNotificationTemplate = async ({ body, templateId }) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/createNotificationTemplate?templateId=${templateId}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const addUpdateNotification = async ({ body, notificationId }) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/sendNotification?notificationId=${notificationId}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addUpdateRiskUserGroup = async ({ body, groupId }) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/riskcontrol/addUpdateUserGroup?groupId=${groupId}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllRiskControlUserGroups = async ({ page }) => {
  try {
    const res = await adminInstance().get(
      `/v1/admin/riskcontrol/userGroups?page=${page}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteRiskControlUserGroups = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/riskcontrol/deleterUserGroup/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};
export const addUpdateGroup = async ({ body, groupId }) => {
  console.log("body===", [...body]);
  try {
    const res = await adminInstance().post(
      `/v1/admin/addUpdateGroup?groupId=${groupId}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deletePaymentChannel = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deletepaymentchannel/${id}`
    );

    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addPaymentMethod = async (formData, id) => {
  console.log("body===", formData, id);
  try {
    const res = await adminInstance().post(
      `/v1/admin/addUpdateMethod${id ? `?methodId=${id}` : ""}`,
      formData
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deletePaymentMethod = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deletepaymentmethods/${id}`
    );

    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllVouchers = async (config) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/paymentvouchers${
        config.params
          ? `?page=${config?.params?.page}&limit=${config?.params?.limit}`
          : ""
      }`,
      config?.body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addVoucher = async (formData) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/addUpdatePaymentVoucher`,
      formData
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createComments = async (body) => {
  try {
    const res = await adminInstance().post("/v1/admin/createComments", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getComments = async (body) => {
  try {
    const res = await adminInstance().get("/v1/admin/getAllComments", body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updateComments = async (body) => {
  try {
    const res = await adminInstance().put(
      `/v1/admin/updateComments/${body?.body.id}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteComments = async (body) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deleteComments/${body?.body?.id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllPaymentRestrictionGroups = async ({ body, page }) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/paymentrestriction/groups?page=${page}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const getAllGlobalLimit = async (config) => {
  const { params, body } = config;
  try {
    const res = await adminInstance().post(
      `/v1/admin/riskcontrol/globalLimits?page=${params.page}&limit=${params.limit}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const addUpdatePaymentRestrictionGroup = async ({
  body,
  restGroupId,
}) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/paymentrestriction/addUpdateGroup?restGroupId=${restGroupId}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllGroupLimit = async (config) => {
  const { params, body } = config;
  console.log(body);
  try {
    const res = await adminInstance().post(
      `/v1/admin/riskcontrol/groupLimits?page=${params.page}&limit=${params.limit}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const getAllPaymentRestrictionUsers = async ({ body, page }) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/paymentrestriction/users?page=${page}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllIndividualLimit = async (config) => {
  const { params, body } = config;
  try {
    const res = await adminInstance().post(
      `/v1/admin/riskcontrol/individualLimits?page=${params.page}&limit=${params.limit}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const addUpdatePaymentRestrictionUser = async ({ body, restUserId }) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/paymentrestriction/addUpdateUser?restUserId=${restUserId}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const AddGroupLimits = async (config, id) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/riskcontrol/addUpdateGroupLimit${
        id ? `?globalLimitId=${id}` : ""
      }`,
      config.body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const AddGlobalLimits = async (config, id) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/riskcontrol/addUpdateGlobalLimit${
        id ? `?groupLimitId=${id}` : ""
      }`,
      config.body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const AddIndividualLimits = async (config, id) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/riskcontrol/addUpdateIndividualLimit${
        id ? `?individualLimitId=${id}` : ""
      }`,
      config.body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteGroupLimit = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/riskcontrol/deleteGroupLimit/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteGlobalLimit = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/riskcontrol/deleteGlobalLimit/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteIndividualLimit = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/riskcontrol/deleteIndividualLimit/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const listOfCPU = async () => {
  try {
    const res = await adminInstance().get(`/v1/admin/listForPaymentMethod`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const kycFormList = async () => {
  try {
    const res = await adminInstance().get(`/v1/admin/riskcontrol/kycForms`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addUpdateKycQuestionnaires = async ({ body, id }) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/riskcontrol/addUpdateKycQuestionnaires?kycQuestionnairesId=${id}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const kycQuestionnaires = async () => {
  try {
    const res = await adminInstance().get(
      `/v1/admin/riskcontrol/kycQuestionnaires`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const kycFormAddUpdate = async ({ body, id }) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/riskcontrol/addUpdateKycForm?kycFormId=${id}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteKycForm = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/riskcontrol/deleteKycForm/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteKycQuestionnaires = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/riskcontrol/kycQuestionnaires/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllMediaTemplate = async (config) => {
  const { params, body, filter } = config;
  console.log("filter", filter);
  try {
    const res = await adminInstance().get(
      `/v1/admin/getAllMediaTemplate?page=${params?.page}&pageSize=${params?.limit}&mediaType=${filter?.mediaType}&bannerType=${filter?.bannerType}&linkCategory=${filter?.linkCategory}&product=${filter?.product}&affiliateId=${filter?.affiliateId}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addUpdateCommissionStage = async (body) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/addUpdateCommissionStage`,
      body
    );
    return res;
  } catch (error) {
    console.log("error", error);
    return error.response.data;
  }
};

export const getAllCommissionStage = async (config) => {
  const { params, body } = config;
  try {
    const res = await adminInstance().get(
      `/v1/admin/getAllCommissionStage?page=${params?.page}&pageSize=${params?.limit}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteCommissionStage = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deleteCommissionStage/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllCommissionStageFtd = async (config) => {
  const { params, body } = config;
  try {
    const res = await adminInstance().get(
      `/v1/admin/getAllCommissionStageFtd?page=${params?.page}&pageSize=${params?.limit}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllCommissionStageMonthly = async (config) => {
  const { params, body } = config;
  try {
    const res = await adminInstance().get(
      `/v1/admin/getAllCommissionStageMonthly?page=${params?.page}&pageSize=${params?.limit}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createMediaTemplate = async (config) => {
  const { body } = config;
  try {
    const res = await adminInstance().post(
      `/v1/admin/createMediaTemplate`,
      body
    );
    return res;
  } catch (error) {
    console.log("error", error);
    return error.response.data;
  }
};

export const createCommissionStageMonthly = async (config) => {
  const { body } = config;
  try {
    const res = await adminInstance().post(
      `/v1/admin/createCommissionStageMonthly`,
      body
    );
    return res;
  } catch (error) {
    console.log("error", error);
    return error.response.data;
  }
};

export const createCommissionStageFtd = async (config) => {
  const { body } = config;
  try {
    const res = await adminInstance().post(
      `/v1/admin/createCommissionStageFtd`,
      body
    );
    return res;
  } catch (error) {
    console.log("error", error);
    return error.response.data;
  }
};

export const updateMediaTemplate = async (config, id) => {
  const { body } = config;
  try {
    const res = await adminInstance().put(
      `/v1/admin/updateMediaTemplate/${id}`,
      body
    );
    return res;
  } catch (error) {
    console.log("error", error);
    return error.response.data;
  }
};

export const updateCommissionStageFtd = async (config, id) => {
  const { body } = config;
  try {
    const res = await adminInstance().put(
      `/v1/admin/updateCommissionStageFtd/${id}`,
      body
    );
    return res;
  } catch (error) {
    console.log("error", error);
    return error.response.data;
  }
};

export const updateCommissionStageMonthly = async (config, id) => {
  const { body } = config;
  try {
    const res = await adminInstance().put(
      `/v1/admin/updateCommissionStageMonthly/${id}`,
      body
    );
    return res;
  } catch (error) {
    console.log("error", error);
    return error.response.data;
  }
};

export const deleteMediaTemplate = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deleteMediaTemplate/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteCommissionStageFtd = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deleteCommissionStageFtd/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteCommissionStageMonthly = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deleteCommissionStageMonthly/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllSystemTransactionData = async (config) => {
  const { params, body, filter } = config;
  try {
    const res = await adminInstance().get(
      `/v1/admin/getTransactionDetails?page=${params?.page}&pageSize=${
        params?.limit
      }&userId=${filter?.userId ? filter?.userId : ""}&toAmount=${
        filter?.toAmount ? filter.toAmount : ""
      }&fromAmount=${filter?.fromAmount ? filter?.fromAmount : ""}&fromDate=${
        filter?.fromDate ? filter?.fromDate : ""
      }&toDate=${filter?.toDate ? filter?.toDate : ""}&id=${
        filter?.id || ""
      }&status=${filter?.status || ""}&transactionType=${
        filter?.transactionType || ""
      }`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllPromotionsClass = async (config) => {
  try {
    const { params } = config;
    const res = await adminInstance().get(
      `/v1/admin/getAllPromotionsClass?${
        params ? `page=${params.page}&pageSize=${params.pageSize}` : ""
      }`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const createPromotionClass = async (config) => {
  try {
    const { body } = config;
    const res = await adminInstance().post(
      "/v1/admin/createPromotionsClass",
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const deletePromotionClass = async (id) => {
  try {
    const res = await adminInstance().delete(
      `/v1/admin/deletePromotionsClass/${id}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const updatePromotionClass = async (id, config) => {
  try {
    const { body } = config;
    const res = await adminInstance().put(
      `/v1/admin/updatePromotionsClass/${id}`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const depositRequestsData = async () => {
  try {
    const res = await adminInstance().get(`/v1/admin/orders/depositRequests`);
    return res;
  } catch (error) {
    return error;
  }
};

export const withdrawRequestsData = async (config) => {
  try {
    const { filter, params } = config;
    console.log(filter, params);
    const res = await adminInstance().get(
      `/v1/admin/orders/withdrawlRequests?page=${params?.page}&limit=${
        params?.limit
      }&userId=${filter?.userId || ""}&toAmount=${
        filter?.toAmount || ""
      }&fromAmount=${filter?.fromAmount || ""}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getFieldOptions = async (action) => {
  try {
    const res = await adminInstance().get(
      `/v1/admin/getFieldOptions?action=${action}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getLoginLogData = async (config) => {
  const { filter, params, body } = config;
  try {
    const res = await adminInstance().get(
      `/v1/admin/riskcontrol/getLoginLogData?page=${params?.page}&pageSize=${
        params?.pageSize
      }${
        filter
          ? `&userId=${filter?.userId || ""}&userName=${
              filter?.userName || ""
            }&ipAddress=${filter?.ipAddress || ""}&Browser=${
              filter?.Browser || ""
            }&os=${filter?.os || ""}&fromDate=${
              filter?.fromDate || ""
            }&toDate=${filter?.toDate || ""}&deviceType=${
              filter?.deviceType || ""
            }`
          : ""
      }`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getRegisterAffiliateData = async (config) => {
  const { filter, params, body } = config;
  try {
    const res = await adminInstance().get(
      `/v1/admin/getRegisterAffiliateData?page=${params?.page}&pageSize=${
        params?.pageSize
      }${
        filter
          ? `&id=${filter?.userId || ""}&userName=${
              filter?.userName || ""
            }&firstName=${filter?.firstName || ""}&lastName=${
              filter?.lastName || ""
            }&email=${filter?.email || ""}&mobile=${
              filter?.mobile || ""
            }&country=${filter?.country || ""}&city=${
              filter?.city || ""
            }&address=${filter?.address || ""}&iskycCompleted=${
              filter?.iskycCompleted || ""
            }&fromBalance=${filter?.fromBalance || ""}&toBalance=${
              filter?.toBalance || ""
            }`
          : ""
      }`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addUpdateRegisterAffiliate = async (body) => {
  try {
    const res = await adminInstance().post(
      `/v1/admin/addUpdateRegisterAffiliate`,
      body
    );
    return res;
  } catch (error) {
    console.log("error", error);
    return error.response.data;
  }
};
export const makeManualDepositWithdraw = async (value) => {
  try {
    const res = await adminInstance().post(
      "/v1/admin/manual-deposit-withdraw",
      value
    );
    return res?.data;
  } catch (error) {
    return error.response.data;
  }
};
