import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";
import GlobalTable from "../../global/globalTable";
import { useEffect, useState } from "react";
import Pagination from "../../../utils/Pagination";
import { useMyContect } from "../../../context/myContext";
import { getAllTransactions } from "../../../utils/Apis";
import { formatDate } from "../../../utils/dateTimeFromater";

const TransactionTable = ({ recall, filter }) => {
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const [pageSize, setPageSize] = useState("20");

  let options = [
    {
      id: 1,
      value: 20,
    },
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
    {
      id: 1,
      value: 500,
    },
  ];
  const [rowData, setRowData] = useState([
    {
      id: 1,
      dateCreated: "[date]",
      username: "AlexTran",
      level: "Master",
      manager: "AlexTran",
      beforeBalance: "50000.00",
      afterBalance: "50000.00",
      actionBy: "AlexTran",
    },
  ]);
  const getAllPlayerData = async (page, pageSize) => {
    try {
      const config = {
        params: {
          page: page,
          pageSize: pageSize,
        },
        filter: filter,
      };
      setLoading(true);
      const res = await getAllTransactions(config);
      if (res.status === 200) {
        setRowData(res.data);
        setLoading(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllPlayerData(page, pageSize);
    // eslint-disable-next-line
  }, [pageSize, page, recall, isLoading]);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      minWidth: 120,
      flex: 1,
      sortable: true,
      cellStyle: { color: "#4caf50", textAlign: "center" },
    },
    {
      headerName: "Date Created",
      field: "createdAt",
      minWidth: 150,
      flex: 1,
      // sortable: true,
      sort: "desc",
      valueFormatter: (params) => formatDate(params?.value),
    },
    {
      headerName: "Transaction By",
      field: "executor",
      minWidth: 180,
      flex: 1,
      valueFormatter: (p) => p?.value?.username,
      cellStyle: { color: "#4caf50", textAlign: "left" },
    },
    {
      headerName: "Transaction For",
      field: "beneficiary",
      minWidth: 150,
      flex: 1,
      valueFormatter: (p) => p?.value?.username || "-",
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Balance",
      field: "amount",
      minWidth: 150,
      flex: 1,
      sortable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Manager Level",
      field: "executor",
      minWidth: 150,
      flex: 1,
      sortable: true,
      valueFormatter: (p) => p?.value?.level,
      cellStyle: { textAlign: "center" },
    },
  ]);

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );

  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  console.log(setRowData, setPages);

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Transactions</h6>
        <div className="right-area">
          <ExportExcel rowData={rowData} tableData={tableData} />
          <SelectInput
            listArray={options}
            defaultOption="20"
            handleSelectOption={(e) => {
              setPageSize(e.target.value ? e.target.value : "20");
            }}
          />
          <TableColumns tableData={tableData} checkedBox={checkedBox} />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable columnDefs={columnDefs} rowData={rowData} />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

export default TransactionTable;
