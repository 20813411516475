import { Form } from "react-bootstrap";

const SelectInput = ({
  name,
  listArray,
  selectedValue,
  handleSelectOption,
  defaultOption,
  isRequired,
  isDisabled,
}) => {
  return (
    <Form.Select
      aria-label="Default select example"
      className="form-floating"
      onChange={handleSelectOption}
      value={selectedValue}
      name={name}
      required={isRequired ? isRequired : false}
      disabled={isDisabled ? isDisabled : false}
    >
      {defaultOption && <option value={defaultOption}>{defaultOption}</option>}
      {listArray &&
        listArray.map((el, i) => (
          <option value={el.value ? el.value : el.text} key={i}>
            {el.text ? el.text : el.value}
          </option>
        ))}
    </Form.Select>
  );
};
export default SelectInput;
