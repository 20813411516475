import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import "ag-grid-community/styles/ag-theme-quartz.css";
import CustomAscIcon from "../../assets/sort-up.png";
import CustomDescIcon from "../../assets/caret-down.png";
import { useMyContect } from "../../context/myContext";
// import { Spinner } from "react-bootstrap";
const GlobalTable = ({
  rowData,
  columnDefs,
  onGridReady,
  pageCss,
  loading,
  setSitepopup,
}) => {
  const { setData } = useMyContect();

  const gridOptions = {
    rowHeight: 60,
    headerHeight: 60,
    domLayout: "autoHeight",
    icons: {
      sortAscending: `<img src="${CustomAscIcon}" height="9px" width="9px" />`,
      sortDescending: `<img src="${CustomDescIcon}" height="9px" width="9px"/>`,
    },
    resizable: true,
    rowSelection: "multiple",
  };

  // const sideBarR = useMemo(() => {
  //   return {
  //     toolPanels: ["columns"],
  //   };
  // }, []);

  const handleClick = (value) => {
    // setSitepopup(value)
    // console.log(value?.data);
    setData(value?.data);
  };
  return (
    <div
      className={`ag-theme-alpine ag-theme-quartz1 ag-grid-table-layout ${pageCss}`}
      style={{ height: "100%", width: "100%" }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        overlayNoRowsTemplate={`<span class="mt-5 d-flex align-items-center text-danger fs-4">No Data Available</span>`}
        style={{ width: "100%", height: "100%" }}
        // onFirstDataRendered={(params) => {
        //   params.api.sizeColumnsToFit();
        // }}
        on={(value) => {
          // console.log(value);
        }}
        onCellClicked={(value) => handleClick(value)}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        defaultColDef={{
          width: "auto",
          resizable: true,
        }}
        loading={loading}
        // defaultColDef={{ resizable: true }}
        // pagination={true}
      ></AgGridReact>
    </div>
  );
};
export default GlobalTable;
