import {
  AccountBalance,
  AccountBalanceWallet,
  AssignmentReturned,
  AttachMoney,
  PersonAdd,
} from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import Skeleton from "@mui/material/Skeleton";
import { useMyContect } from "../../context/myContext";
const BoxComponent = ({ isLoading }) => {
  const { user } = useMyContect();
  return (
    <div className="box-section">
      <div className="box profit-box">
        <div className="left-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="50px" />
          ) : (
            <AccountBalanceWallet />
          )}
          <div className="inner-box">
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="50px" />
            ) : (
              <h6>Profit</h6>
            )}
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="10px" />
            ) : (
              <p>USD</p>
            )}
          </div>
        </div>
        <div className="right-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="10px" />
          ) : (
            <h6>0.00</h6>
          )}
        </div>
      </div>
      <div className="box profit-box">
        <div className="left-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="50px" />
          ) : (
            <PersonAdd />
          )}
          <div className="inner-box">
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="50px" />
            ) : (
              <h6>Registered Players</h6>
            )}
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="50px" />
            ) : (
              <p>Total</p>
            )}
          </div>
        </div>
        <div className="right-box inner-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="10px" />
          ) : (
            <h6>1</h6>
          )}
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="10px" />
          ) : (
            <p>30</p>
          )}
        </div>
      </div>
      <div className="box profit-box">
        <div className="left-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="50px" />
          ) : (
            <AttachMoney />
          )}
          <div className="inner-box">
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="40px" />
            ) : (
              <h6>Deposit</h6>
            )}
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="50px" />
            ) : (
              <p>USD</p>
            )}
          </div>
        </div>
        <div className="right-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="10px" />
          ) : (
            <h6>0</h6>
          )}
        </div>
      </div>
      <div className="box withdraw-box">
        <div className="left-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="50px" />
          ) : (
            <span className="withdraw-icon">
              <AssignmentReturned />
            </span>
          )}
          <div className="inner-box">
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="50px" />
            ) : (
              <h6>Withdraw</h6>
            )}
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="50px" />
            ) : (
              <p>USD</p>
            )}
          </div>
        </div>
        <div className="right-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="10px" />
          ) : (
            <h6>0</h6>
          )}
        </div>
      </div>
      {console.log(user)}
      <div className="box profit-box money-box">
        <div className="left-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="50px" />
          ) : (
            <AccountBalance />
          )}
          <div className="inner-box">
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="100px" />
            ) : (
              <h6>Money in System</h6>
            )}
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="50px" />
            ) : (
              <p>USD</p>
            )}
          </div>
        </div>
        <div className="right-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="10px" />
          ) : (
            <h6>{user?.balance}</h6>
          )}
        </div>
      </div>
      <div className="box">
        <div className="left-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="50px" />
          ) : (
            <span className="player-count-icon">
              <GroupIcon />
            </span>
          )}
          <div className="inner-box">
            {isLoading ? (
              <Skeleton animation="wave" height="10px" width="50px" />
            ) : (
              <h6>Player Count</h6>
            )}
            {/* <p>USD</p> */}
          </div>
        </div>
        <div className="right-box">
          {isLoading ? (
            <Skeleton animation="wave" height="10px" width="10px" />
          ) : (
            <h6>1</h6>
          )}
        </div>
      </div>
    </div>
  );
};
export default BoxComponent;
